//User reducer types
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const SET_USER = "SET_USER";
export const LOADING_USER = "LOADING_USER";
export const MARK_NOTIFICATION_READ = "MARK_NOTIFICATION_READ";

//UI reducer types
export const SET_ERRORS = "SET_ERRORS";
export const LOADING_UI = "LOADING_UI";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const LOADING_DATA = "LOADING_DATA";
export const STOP_LOADING_UI = "STOP_LOADING_UI";

//Data reducer types
export const SET_TWEETS = "SET_TWEETS";
export const SET_TWEET = "SET_TWEET";
export const LIKE_TWEET = "LIKE_TWEET";
export const UNLIKE_TWEET = "UNLIKE_TWEET";
export const DELETE_TWEET = "DELETE_TWEET";
export const POST_TWEET = "POST_TWEET";
export const POST_COMMENT = "POST_COMMENT";
